import axios from 'axios';

const http = axios.create({
  // baseURL: 'http://120.77.78.57:8080', // 设置API基础路径，可以通过环境变量配置
  // baseURL: 'http://192.168.50.196:8080', // 设置API基础路径，可以通过环境变量配置
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    // 在这里可以添加请求头、认证token等
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  },
);

export default http;